import React, { useContext, useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import Layout from "../../components/Layout";
import PlainPage from "../../templates/plain-page";
import { firebaseApp, db } from "../../components/firebase/firestore";
import { firebaseEmailSettings } from "../../components/auth/Auth";

const Content = () => {
  return (
    <div className="column is-10 is-offset-1">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Private page
      </h2>
      This page is visible only to logged in users.
    </div>
  );
};

const PrivatePage = () => {
  return <PlainPage Content={Content} isPrivate />;
};

export default PrivatePage;
